import React from 'react'

import Layout from '../components/layout'
import {Header, Container, Image} from 'semantic-ui-react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

class PicturesPage extends React.Component {

    render(){
        const paths = this.props.data.allFile.edges.map((e) => {
            return e.node.childImageSharp.fluid
        })
        console.log('data', paths)

    return(
  <Layout>
    <Container style={{marginTop: '4em'}} textAlign='center'>
        {paths.map(e => (
            <Img fluid={e} />
        ))}
    </Container>
  </Layout>
    )
    }
}


export const qeury = graphql`
{
    allFile {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100) {
              base64
              tracedSVG
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
  }
  
  
`

export default PicturesPage